const applyConfig = (config) => {
  config.settings.isMultilingual = false;
  config.settings.supportedLanguages = ['en'];
  config.settings.defaultLanguage = 'en';
  config.blocks.themes = [
    {
      style: {
        '--theme-color': 'white',
        '--theme-high-contrast-color': 'black',
        '--theme-foreground-color': 'black',
        '--theme-low-contrast-foreground-color': 'var(--lightgrey)',
      },
      name: 'white',
      label: 'White',
    },
    {
      style: {
        '--theme-color': 'var(--lightgrey)',
        '--theme-high-contrast-color': 'black',
        '--theme-foreground-color': 'black',
        '--theme-low-contrast-foreground-color': 'var(--lightgrey)',
      },
      name: 'lightgrey',
      label: 'Light Grey',
    },
    {
      style: {
        '--theme-color': 'var(--blue)',
        '--theme-high-contrast-color': 'white',
        '--theme-foreground-color': 'white',
        '--theme-low-contrast-foreground-color': 'var(--lightgrey)',
      },
      name: 'blue',
      label: 'Blue',
    },
  ];
  return config;
};

export default applyConfig;
