/**
 * Footer component.
 * @module components/theme/Footer/Footer
 */

import React from 'react';
import {
  Container,
  List,
  Segment,
  Grid,
  //GridRow,
  GridColumn,
  //Icon,
  //Button,
  //ButtonContent,
} from 'semantic-ui-react';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';
import { UniversalLink } from '@plone/volto/components';

import { useGoogleAnalytics } from 'volto-google-analytics';
const messages = defineMessages({
  copyright: {
    id: 'Copyright',
    defaultMessage: 'Copyright',
  },
});

/**
 * Component to display the footer.
 * @function Footer
 * @param {Object} intl Intl object
 * @returns {string} Markup of the component
 */
const Footer = ({ intl }) => {
  useGoogleAnalytics();
  return (
    <div class="footer-container print_hide">
      <Segment
        role="contentinfo"
        vertical
        padded
        inverted
        id="footer"
        aria-label="Footer"
      >
        <Container>
          <div id="footer-main">
            <Grid padded stackable>
              <GridColumn width={2} textAlign="left">
                <List>
                  <List.Header>
                    <h4>Nav</h4>
                  </List.Header>
                  <List.Content>
                    <List.Item>
                      <UniversalLink href="/services">Services</UniversalLink>
                    </List.Item>
                    <List.Item>
                      <UniversalLink href="/portfolio">Portfolio</UniversalLink>
                    </List.Item>
                    <List.Item>
                      <UniversalLink href="/pricing">Pricing</UniversalLink>
                    </List.Item>
                    <List.Item>
                      <UniversalLink href="/team">The Team</UniversalLink>
                    </List.Item>
                    <List.Item>
                      <UniversalLink href="/websites-for-non-profits">
                        Websites for non-profits
                      </UniversalLink>
                    </List.Item>
                    <List.Item>
                      <UniversalLink href="/news">News</UniversalLink>
                    </List.Item>
                  </List.Content>
                </List>
              </GridColumn>
              <GridColumn width={2} textAlign="left">
                <List>
                  <List.Header>
                    <h4>Quick Links</h4>
                  </List.Header>
                  <List.Content>
                    <List.Item>
                      <UniversalLink href="/contact">Contact Us</UniversalLink>
                    </List.Item>
                    <List.Item>
                      <UniversalLink href="/subscribe-to-our-mailer">
                        Subscribe to our mailer
                      </UniversalLink>
                    </List.Item>
                    <List.Item>
                      <UniversalLink href="/privacy-policy">
                        Privacy Policy
                      </UniversalLink>
                    </List.Item>
                    <List.Item>
                      <UniversalLink href="/terms-and-conditions">
                        Terms & Conditions
                      </UniversalLink>
                    </List.Item>
                    <List.Item>
                      <UniversalLink href="/paia-manual">
                        PAIA Manual
                      </UniversalLink>
                    </List.Item>
                    <hr />
                    <List.Item>
                      <List horizontal>
                        <List.Item>
                          <UniversalLink
                            href="https://www.linkedin.com/company/juizi-cc"
                            target="_blank"
                          >
                            <i class="bi bi-linkedin"></i>
                          </UniversalLink>
                        </List.Item>
                        <List.Item>
                          <UniversalLink
                            href="https://x.com/juizi_web"
                            target="_blank"
                          >
                            <i class="bi bi-twitter-x"></i>
                          </UniversalLink>
                        </List.Item>
                        <List.Item>
                          <UniversalLink
                            href="https://www.youtube.com/@juiziweb"
                            target="_blank"
                          >
                            <i class="bi bi-youtube"></i>
                          </UniversalLink>
                        </List.Item>
                      </List>
                    </List.Item>
                  </List.Content>
                </List>
              </GridColumn>
              <GridColumn width={8} textAlign="right" verticalAlign="bottom">
                <List>
                  <List.Content>
                    <List.Item>
                      <FormattedMessage
                        id="{copyright} juizi {current_year}"
                        defaultMessage="{copyright} Juizi {current_year}"
                        values={{
                          copyright: (
                            <abbr
                              title={intl.formatMessage(messages.copyright)}
                            >
                              ©
                            </abbr>
                          ),
                          current_year: new Date().getFullYear(),
                        }}
                      />
                    </List.Item>
                    <List.Item>
                      <UniversalLink href="#top" class="header-link">
                        <i class="bi bi-arrow-up-circle-fill"></i>
                      </UniversalLink>
                    </List.Item>
                  </List.Content>
                </List>
              </GridColumn>
            </Grid>
          </div>
        </Container>
      </Segment>
    </div>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
Footer.propTypes = {
  /**
   * i18n object
   */
};

export default injectIntl(Footer);
